import { gql, TypedDocumentNode } from "@apollo/client";
import { UserFragment } from "../generated";

const USER_FRAGMENT: TypedDocumentNode<UserFragment> = gql`
  fragment User on User {
    id
    communities {
      id
      name
    }
    role
    propertyManager {
      id
      name
    }
    email
    enabledFeatures
  }
`;

export default USER_FRAGMENT;
