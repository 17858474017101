import USER_FRAGMENT from "@/graphql/fragments/User";
import { GetUserQuery } from "@/graphql/generated";
import { gql, TypedDocumentNode } from "@apollo/client";

const GET_USER: TypedDocumentNode<GetUserQuery> = gql`
  query getUser {
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_USER;
