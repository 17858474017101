import { ApolloProvider } from "@apollo/client";

import Loader from "@/components/Loader";
import useConnect from "./connect";
import type { Props } from "./types";

export default function GraphQLProvider({
  children,
  useLogoutError,
}: Props): JSX.Element {
  const { client, isLoading } = useConnect(useLogoutError);

  if (isLoading) return <Loader />;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
