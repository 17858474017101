/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useEffect,
  useContext,
  createContext,
  ReactNode,
  useMemo,
} from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useRouter } from "next/router";
import useCurrentUser from "@/graphql/hooks/user/useUser";
import { datadogRum } from "@datadog/browser-rum";

const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

type Props = {
  children: ReactNode;
};
export const AnalyticsProvider = ({ children }: Props) => {
  const { data: user, loading: userDataLoading, auth0User } = useCurrentUser();
  const router = useRouter();
  const writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || "";
  const cdnURL = "https://s-cdn.heyrenew.com";
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey, cdnURL }),
    [writeKey]
  );

  // capture first page view when initially loaded
  useEffect(() => {
    void analytics.page();
  }, [analytics]);

  // send a page event on every routeChangeComplete event
  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      void analytics.page();
    });
  }, [router.events, analytics]);

  // send analytics.identify and set DD user once useUser has resolved
  // use user details if user present, otherwise use
  // segments default anonymous user by sending a null
  // user id
  useEffect(() => {
    if (!userDataLoading) {
      if (Boolean(user) && user && auth0User && Boolean(auth0User)) {
        // TO-DO: add other items needed for identify other than just email & name
        void analytics.identify(user.id, {
          email: user.email,
          name: auth0User.name,
        });
        datadogRum?.setUser?.({
          email: user.email,
          id: user.id,
        });
      } else {
        void analytics.identify();
      }
    }
  }, [analytics, user, userDataLoading, auth0User]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
