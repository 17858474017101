/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/await-thenable */
import setupClient from "@/graphql/client";
import { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";

export default function useConnect(useLogoutError: () => void) {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
            },
          });
          await setAccessToken(token);
        } catch (e) {
          datadogRum.addError(e);
        }
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const client = useMemo(
    () =>
      setupClient({
        useLogoutError,
        accessToken,
      }),
    [useLogoutError, accessToken]
  );
  const isLoading = !accessToken && isAuthenticated;

  return { client, isLoading };
}
