/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

export default function setupClient({
  useLogoutError,
  accessToken,
}: {
  useLogoutError: () => void;
  accessToken?: string;
}) {
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  });

  const authLink = setContext((_, { headers }) => {
    const token = accessToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "X-Origin-Service": "partner-app",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    const deniedPermission = Boolean(
      graphQLErrors?.find((error) => error.message === "Permission Denied")
    );

    if (deniedPermission) {
      useLogoutError();
    }
  });

  const link = from([errorLink, authLink, httpLink]);
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Document: {
          keyFields: ["id", "type"],
        },
      },
    }),
    link,
  });
}
