import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import defaultTheme from "@/styles/themes/default";

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  margin-top: 10rem;
`;

export const LoaderImage = styled(CircularProgress)`
  color: ${defaultTheme.renewTheme.colors.night400};
  height: 2.5rem;
  width: 2.5rem;
`;
