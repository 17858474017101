/* eslint-disable @typescript-eslint/no-floating-promises */
import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useCallback, useState } from "react";

function GraphQlErrorBoundary({
  children,
}: {
  children: (showError: () => void) => JSX.Element;
}) {
  const [logoutError, setLogoutError] = useState(false);
  const useLogoutError = useCallback(() => setLogoutError(true), []);

  const { logout } = useAuth0();
  const redirectUri =
    typeof window !== "undefined" ? window.location.origin : "";

  if (logoutError) {
    (async () => {
      await logout({
        logoutParams: {
          returnTo: redirectUri ? `${redirectUri}` : "",
        },
      }).catch((e) => {
        datadogRum.addError(e);
      });
    })();
  }

  return <>{!logoutError && children(useLogoutError)}</>;
}

export default GraphQlErrorBoundary;
