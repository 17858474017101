export enum Role {
  SITE = "site",
  REGIONAL = "regional",
  EXECUTIVE = "executive",
}

export const normalizeRole = (input: "SITE" | "REGIONAL" | "EXECUTIVE") => {
  switch (input) {
    case "SITE":
      return Role.SITE;
    case "REGIONAL":
      return Role.REGIONAL;
    case "EXECUTIVE":
      return Role.EXECUTIVE;
    default:
      return Role.SITE;
  }
};
