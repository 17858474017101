import { memo } from "react";

import * as Styled from "./styles";
import type { Props } from "./types";

function Loader({ className }: Props): JSX.Element {
  return (
    <Styled.Container className={className}>
      <Styled.LoaderImage />
    </Styled.Container>
  );
}

export default memo(Loader);
