import type { WithoutNulls } from "@/utils/withoutNulls";
import { UserFragment } from "@/graphql/generated";
import { normalizeRole } from "./Role";

export default function normalizeUser(input: WithoutNulls<UserFragment>) {
  return {
    id: input.id,
    communities: input.communities,
    role: normalizeRole(input.role),
    propertyManager: input.propertyManager,
    email: input.email,
    enabledFeatures: input.enabledFeatures,
  };
}
export type User = ReturnType<typeof normalizeUser>;
